import classNames from 'classnames/bind';
import React from 'react';

import { List } from '@components/List/List';
import { Paragraph } from '@components/Paragraph/Paragraph';
import { trackClickEvent } from '@gaEvents';

import styles from './QuestionBox.module.scss';
import AngleDownIcon from '@icons/angleDown.svg';

export type QuestionBoxType = 'default' | 'lg';

type Text = string | { simple: string; render: JSX.Element };

export interface IQuestionBox {
  question: string;
  answer: Text | (Text | Text[])[];
  type?: QuestionBoxType;
  openOnLoad?: boolean;
}

interface IProps extends IQuestionBox {
  index: number;
}

const cx = classNames.bind(styles);

export const QuestionBox: React.FC<IProps> = ({
  index,
  answer,
  question,
  type = 'default',
  openOnLoad,
}) => {
  const [isVisible, setIsVisible] = React.useState(openOnLoad);

  return (
    <div className={cx('wrapper', type, { isVisible })}>
      <dt>
        <button
          aria-controls={`faq-question-${index}`}
          aria-expanded={isVisible}
          className={styles.question}
          id={`faq-answer-${index}`}
          type="button"
          onClick={() => {
            if (!isVisible) {
              trackClickEvent(question, 'faq');
            }
            setIsVisible(!isVisible);
          }}
        >
          {question}
          <span className={styles.iconWrapper}>
            <AngleDownIcon className={styles.icon} />
          </span>
        </button>
      </dt>
      <dd
        aria-labelledby={`faq-answer-${index}`}
        className={styles.answer}
        id={`faq-question-${index}`}
      >
        {Array.isArray(answer) ? (
          answer.map((text, index) => {
            if (Array.isArray(text)) {
              return (
                <List
                  key={typeof text[0] === 'string' ? text[0] : text[0].simple}
                  items={text.map(item => (typeof item === 'string' ? item : item.render))}
                  ulClassName={cx({ 'mb-4': index !== answer.length - 1 })}
                />
              );
            }

            return (
              <Paragraph
                key={typeof text === 'string' ? text : text.simple}
                className={cx({ 'mb-4': index !== answer.length - 1 })}
                dangerouslySetInnerHTML={typeof text === 'string' ? { __html: text } : undefined}
              >
                {typeof text !== 'string' ? text.render : null}
              </Paragraph>
            );
          })
        ) : (
          <Paragraph
            dangerouslySetInnerHTML={typeof answer === 'string' ? { __html: answer } : undefined}
          >
            {typeof answer !== 'string' ? answer.render : null}
          </Paragraph>
        )}
      </dd>
    </div>
  );
};
