import React from 'react';

import { FaqStructuredData } from './FaqStructuredData';
import type { IQuestionBox } from './QuestionBox/QuestionBox';
import { QuestionBox } from './QuestionBox/QuestionBox';

export interface IProps {
  data: IQuestionBox[];
  openFirstItem?: boolean;
}

export const Faq: React.FC<IProps> = ({ data, openFirstItem }) => (
  <>
    <FaqStructuredData data={data} />
    <dl className="mb-0">
      {data.map(({ question, answer }, index) => (
        <QuestionBox
          key={question}
          answer={answer}
          index={index}
          openOnLoad={openFirstItem && index === 0}
          question={question}
          type="lg"
        />
      ))}
    </dl>
  </>
);
